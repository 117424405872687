<template>
  <div v-if="operationMode !== OperationMode.Monitor" class="guide-bar__expand">
    <div @click="openGuideBar">
      <v-tooltip right color="black">
        <template #activator="{ on, attrs }"
          ><v-icon large v-bind="attrs" color="#404142" class="guide-bar__sidebar-icon" v-on="on">{{
            expand ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right'
          }}</v-icon>
        </template>
        <div class="d-flex flex-row align-center pa-3">
          <h2>Collapse</h2>
        </div>
      </v-tooltip>
    </div>

    <v-divider class="grey lighten-1"></v-divider>

    <div v-if="operationMode === OperationMode.Participant" @click="activeStep = unlockedStep">
      <v-tooltip right color="black">
        <template #activator="{ on, attrs }"
          ><v-icon large v-bind="attrs" color="green" class="guide-bar__sidebar-icon" v-on="on"
            >mdi-transit-connection-variant</v-icon
          >
        </template>
        <div class="d-flex flex-row align-center pa-3">
          <h2>Program Guide</h2>
        </div>
      </v-tooltip>
    </div>

    <div v-if="operationMode === OperationMode.Setup" @click="activeStep = unlockedStep">
      <v-tooltip right color="black">
        <template #activator="{ on, attrs }"
          ><v-icon large v-bind="attrs" color="orange" class="guide-bar__sidebar-icon" v-on="on"
            >mdi-progress-wrench</v-icon
          >
        </template>
        <div class="d-flex flex-row align-center pa-3">
          <h2>Setup Program</h2>
        </div>
      </v-tooltip>
    </div>

    <!-- <v-divider class="grey lighten-1"></v-divider>

    <v-tooltip right color="black">
      <template #activator="{ on, attrs }">
        <a
          href="https://pilotcity.notion.site/6f6374a464c54774952f8d6c5978e916?v=f38f43cae54e43808de5d5c5eefcb6eb"
          target="_blank"
          style="text-decoration: none"
        >
          <v-icon v-bind="attrs" color="#404142" class="guide-bar__sidebar-icon" large v-on="on"
            >mdi-bell</v-icon
          ></a
        >
      </template>
      <div class="d-flex flex-row align-center pa-3">
        <h2>Announcements</h2>
      </div>
    </v-tooltip> -->

    <v-divider class="grey lighten-1"></v-divider>

    <div v-if="operationMode === OperationMode.Setup && isPublished">
      <v-tooltip right color="black">
        <template #activator="{ on, attrs }"
          ><v-icon
            v-bind="attrs"
            color="#404142"
            large
            class="guide-bar__sidebar-icon"
            v-on="on"
            @click="
              $router.push({
                name: 'Monitor',
                query: { program: programDoc.data._id.toString() }
              })
            "
            >mdi-monitor-dashboard</v-icon
          >
        </template>
        <div class="d-flex flex-row align-center pa-3">
          <h2>Monitor Program</h2>
        </div>
      </v-tooltip>
    </div>

    <!-- <v-divider class="grey lighten-1"></v-divider>

    <v-tooltip right color="black">
      <template #activator="{ on, attrs }">
        <a
          href="https://pilotcity.notion.site/6f6374a464c54774952f8d6c5978e916?v=f38f43cae54e43808de5d5c5eefcb6eb"
          target="_blank"
          style="text-decoration: none"
        >
          <v-icon v-bind="attrs" color="#404142" class="guide-bar__sidebar-icon" large v-on="on"
            >mdi-bell</v-icon
          ></a
        >
      </template>
      <div class="d-flex flex-row align-center pa-3">
        <h2>Announcements</h2>
      </div>
    </v-tooltip> -->

    <v-divider class="grey lighten-1"></v-divider>

    <router-link :to="{ name: 'portfolio' }">
      <v-tooltip right color="black">
        <template #activator="{ on, attrs }"
          ><v-icon v-bind="attrs" color="#404142" class="guide-bar__sidebar-icon" large v-on="on"
            >mdi-fingerprint</v-icon
          >
        </template>
        <div class="d-flex flex-row align-center pa-3">
          <h2>My Portfolio</h2>
        </div>
      </v-tooltip>
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { OperationMode } from '@/constants/operationMode';

export default defineComponent({
  props: {
    operationMode: {
      required: true,
      type: Number
    }
  },
  setup() {}
});
</script>

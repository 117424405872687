var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.operationMode !== _vm.OperationMode.Monitor)?_c('div',{staticClass:"guide-bar__expand"},[_c('div',{on:{"click":_vm.openGuideBar}},[_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"guide-bar__sidebar-icon",attrs:{"large":"","color":"#404142"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.expand ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right'))])]}}],null,false,3852862100)},[_c('div',{staticClass:"d-flex flex-row align-center pa-3"},[_c('h2',[_vm._v("Collapse")])])])],1),_c('v-divider',{staticClass:"grey lighten-1"}),(_vm.operationMode === _vm.OperationMode.Participant)?_c('div',{on:{"click":function($event){_vm.activeStep = _vm.unlockedStep}}},[_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"guide-bar__sidebar-icon",attrs:{"large":"","color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-transit-connection-variant")])]}}],null,false,570143029)},[_c('div',{staticClass:"d-flex flex-row align-center pa-3"},[_c('h2',[_vm._v("Program Guide")])])])],1):_vm._e(),(_vm.operationMode === _vm.OperationMode.Setup)?_c('div',{on:{"click":function($event){_vm.activeStep = _vm.unlockedStep}}},[_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"guide-bar__sidebar-icon",attrs:{"large":"","color":"orange"}},'v-icon',attrs,false),on),[_vm._v("mdi-progress-wrench")])]}}],null,false,645224749)},[_c('div',{staticClass:"d-flex flex-row align-center pa-3"},[_c('h2',[_vm._v("Setup Program")])])])],1):_vm._e(),_c('v-divider',{staticClass:"grey lighten-1"}),(_vm.operationMode === _vm.OperationMode.Setup && _vm.isPublished)?_c('div',[_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"guide-bar__sidebar-icon",attrs:{"color":"#404142","large":""},on:{"click":function($event){_vm.$router.push({
              name: 'Monitor',
              query: { program: _vm.programDoc.data._id.toString() }
            })}}},'v-icon',attrs,false),on),[_vm._v("mdi-monitor-dashboard")])]}}],null,false,3295954313)},[_c('div',{staticClass:"d-flex flex-row align-center pa-3"},[_c('h2',[_vm._v("Monitor Program")])])])],1):_vm._e(),_c('v-divider',{staticClass:"grey lighten-1"}),_c('router-link',{attrs:{"to":{ name: 'portfolio' }}},[_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"guide-bar__sidebar-icon",attrs:{"color":"#404142","large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-fingerprint")])]}}],null,false,1155774380)},[_c('div',{staticClass:"d-flex flex-row align-center pa-3"},[_c('h2',[_vm._v("My Portfolio")])])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }